import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "./logo2.jpg",
        "alt": null
      }}></img>{` `}</p>
    <p>{`Welcome to the Heart of the Matter, a series in which we share conversations with inspiring and interesting people and dive into the core issues or motivations behind their work, their lives, and their worldview. Coming to you from Austin and the Bay Area with your hosts Bryan Davis and Jay Kannaiyan.`}</p>
    <h5>{`Listen to the show on these platforms`}</h5>
    <p><a parentName="p" {...{
        "href": "https://podcasts.apple.com/us/podcast/the-heart-of-the-matter/id1509391541",
        "title": "Apple Podcasts"
      }}><img parentName="a" {...{
          "src": "https://d12xoj7p9moygp.cloudfront.net/images/podcast/logo-square/006/apple_podcasts.png",
          "alt": null,
          "title": "Apple Podcasts"
        }}></img></a>{`
`}<a parentName="p" {...{
        "href": "https://www.google.com/podcasts?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy8xZDljNmNhYy9wb2RjYXN0L3Jzcw==",
        "title": "Google Podcasts"
      }}><img parentName="a" {...{
          "src": "https://d12xoj7p9moygp.cloudfront.net/images/podcast/logo-square/006/google_podcasts.png",
          "alt": null,
          "title": "Google Podcasts"
        }}></img></a>{`
`}<a parentName="p" {...{
        "href": "https://open.spotify.com/show/1ZvTtMTKLpXl1xnDuCpFWb",
        "title": "Spotify"
      }}><img parentName="a" {...{
          "src": "https://d12xoj7p9moygp.cloudfront.net/images/podcast/logo-square/006/spotify.png",
          "alt": null,
          "title": "Spotify"
        }}></img></a>{`
`}<a parentName="p" {...{
        "href": "https://www.stitcher.com/podcast/the-heart-of-the-matter-2",
        "title": "Stitcher"
      }}><img parentName="a" {...{
          "src": "https://d12xoj7p9moygp.cloudfront.net/images/podcast/logo-square/006/stitcher_dark.png",
          "alt": null,
          "title": "Stitcher"
        }}></img></a>{`
`}<a parentName="p" {...{
        "href": "https://www.breaker.audio/the-heart-of-the-matter-3",
        "title": "Breaker"
      }}><img parentName="a" {...{
          "src": "https://d12xoj7p9moygp.cloudfront.net/images/podcast/logo-square/006/breaker.png",
          "alt": null,
          "title": "Breaker"
        }}></img></a>{`
`}<a parentName="p" {...{
        "href": "https://castbox.fm/channel/the-heart-of-the-matter-id2795672",
        "title": "CastBox"
      }}><img parentName="a" {...{
          "src": "https://d12xoj7p9moygp.cloudfront.net/images/podcast/logo-square/006/castbox.png",
          "alt": null,
          "title": "CastBox"
        }}></img></a>{`
`}<a parentName="p" {...{
        "href": "https://pca.st/8iii4hdn",
        "title": "Pocket Casts"
      }}><img parentName="a" {...{
          "src": "https://d12xoj7p9moygp.cloudfront.net/images/podcast/logo-square/006/pocket_casts.png",
          "alt": null,
          "title": "Pocket Casts"
        }}></img></a>{`
`}<a parentName="p" {...{
        "href": "https://radiopublic.com/the-heart-of-the-matter-GMwDjN",
        "title": "Radio Public"
      }}><img parentName="a" {...{
          "src": "https://d12xoj7p9moygp.cloudfront.net/images/podcast/logo-square/006/radiopublic.png",
          "alt": null,
          "title": "Radio Public"
        }}></img></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      